<template>
  <div>
    <page-title :title="$store.state.pageTitle" :breadcrumbs-items="breadcrumbsItems"></page-title>
    <v-row>
      <v-col>
        <v-btn @click="getData()" color="primary">Reload</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card :loading="loading">
          <v-list two-line>
            <v-list-item-group active-class="pink--text" multiple>
              <template v-for="(item, index) in items">
                <v-list-item :key="item.id" @click="gotoNotificationUrl(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.content"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text v-text="getOldtime(item.notif_date)"></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>

                <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
      {
        text: 'Notification',
        disabled: true,
        href: '#',
      },
    ],
    items: [],
    loading: null,
  }),
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }
  },
  mounted() {
    this.setTitle('Notification')

    this.getData()
  },

  methods: {
    async getData() {
      this.loading = true
      // this.notifications = []
      await this.$axios
        .get('notification/data')
        .then(response => {
          this.items = response.data.data

          // console.log(response.data.data);

          this.loading = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.loading = false
        })
        .then(() => {
          this.loading = false
        })
    },

    getOldtime(t) {
      var DateDiff = {
        inSecond: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / 1000)
        },

        inMinutes: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (60 * 1000))
        },

        inHours: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (3600 * 1000))
        },

        inDays: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (24 * 3600 * 1000))
        },

        inWeeks: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7))
        },

        inMonths: function (d1, d2) {
          var d1Y = d1.getFullYear()
          var d2Y = d2.getFullYear()
          var d1M = d1.getMonth()
          var d2M = d2.getMonth()

          return d2M + 12 * d2Y - (d1M + 12 * d1Y)
        },

        inYears: function (d1, d2) {
          return d2.getFullYear() - d1.getFullYear()
        },
      }
      var d1 = new Date(t)
      var d2 = new Date()

      if (DateDiff.inSecond(d1, d2) <= 30) {
        return 'just now'
      } else if (DateDiff.inSecond(d1, d2) > 30 && DateDiff.inSecond(d1, d2) <= 59) {
        return DateDiff.inSecond(d1, d2).toString() + ' seconds ago'
      } else if (DateDiff.inMinutes(d1, d2) <= 59) {
        return DateDiff.inMinutes(d1, d2).toString() + ' minutes ago'
      } else if (DateDiff.inHours(d1, d2) <= 24) {
        return DateDiff.inHours(d1, d2).toString() + ' hours ago'
      } else if (DateDiff.inDays(d1, d2) <= 30) {
        return DateDiff.inDays(d1, d2).toString() + ' days ago'
      } else if (DateDiff.inMonths(d1, d2) <= 12) {
        return DateDiff.inMonths(d1, d2).toString() + ' months ago'
      } else if (DateDiff.inYears(d1, d2) <= 12) {
        return DateDiff.inYears(d1, d2).toString() + ' years ago'
      }

      // console.log(DateDiff.inMinutes(d1, d2))
      return DateDiff.inMinutes(d1, d2)
    },

    setRead(id) {
      this.$axios
        .get('notification/read', {
          params: {
            id: id,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response)
          this.getData()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },

    gotoNotificationUrl(notifData) {
      this.setRead(notifData.id)
      this.goToExternalUrl(notifData.redirect_url)
    },
  },
}
</script>
